import { css } from '@emotion/react';
import { Box, List, ListItem, Stack, styled } from '@mui/joy';
import { Link } from '@tanstack/react-router';

import LKWLogo from '@/assets/icons/lkw-lotse.svg?react';
import IvmLogo from '@/assets/logos/ivm.png';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';

const links = [
  {
    title: 'Über das Projekt',
    href: '/ueber',
  },
  {
    title: 'Kontakt',
    href: '/kontakt',
  },
  {
    title: 'Impressum',
    href: '/impressum',
  },
  {
    title: 'Datenschutz',
    href: '/datenschutz',
  },
];

const Root = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    flex: 1;
    padding-top: ${theme.spacing(1)};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      justify-content: space-between;
      padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    }
  `,
);

const LogoWrapper = styled(Stack)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      display: flex;
    }
  `,
);

const CenteredContainer = styled(Box)(
  ({ theme }) => css`
    display: none;
    position: fixed;
    left: 50%;
    transform: translate(-50%);

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      display: flex;
      flex-direction: row;
    }
  `,
);

const StyledLink = styled(Link)(
  ({ theme }) => css`
    font-size: ${theme.vars.fontSize.md};
    color: inherit;
    text-decoration: none;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      font-size: ${theme.vars.fontSize.sm};
    }
  `,
);

const StyledList = styled(List)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(1.5)};
    font-weight: 500;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      flex-direction: row;
      justify-content: flex-end;
      padding: 0;
    }
  `,
);

export function Footer() {
  return (
    <Root component="footer">
      <LogoWrapper>
        <img src={IvmLogo} alt="Ivm-Logo" style={{ height: '48px' }} />
      </LogoWrapper>
      <CenteredContainer>
        <LKWLogo />
      </CenteredContainer>

      <Box>
        <StyledList role="menubar">
          {links.map(({ title, href }) => (
            <ListItem sx={{ padding: 0 }} role="none" key={title}>
              <StyledLink to={href} role="menuitem">
                {title}
              </StyledLink>
            </ListItem>
          ))}
        </StyledList>
      </Box>
    </Root>
  );
}
